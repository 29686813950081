<template>
  <b-sidebar
    id="SidebarFilterProduct"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="SidebarFilterProduct"
    width="85vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <div class="mt-3">
        <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            @keyup.enter="submitAction"
            placeholder="Search Product name, Barcode, Article No."
            v-model="filter.search"
          >
          </b-form-input>
        </b-input-group>
      </div>
      <div class="mt-3">
        <b-form-group>
          <label class="main-label">Product Type</label><br />
          <b-form-checkbox v-model="allSelected" @change="toggleAll">
            All
          </b-form-checkbox>
          <b-form-checkbox-group
            id="sale_chanel"
            v-model="selected"
            :options="productType"
            value-field="id"
            text-field="name"
            name="type"
            class="ml-4"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="mt-3">
        <b-form-group>
          <label class="font-weight-bold mb-2">Status: </label><br />
          <b-form-checkbox
            v-model="allSelectedStatus"
            @change="toggleAllStatus"
          >
            All
          </b-form-checkbox>
          <b-form-checkbox-group
            id="status"
            v-model="selectedStatus"
            :options="status"
            value-field="id"
            text-field="name"
            name="status"
            class="ml-4"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    productType: {
      required: true,
      type: Array,
    },
    status: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      selectedStatus: [],
      allSelected: false,
      allSelectedStatus: false,
      selected: [],
    };
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelected = false;
      } else if (newValue.length === this.productType.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    selectedStatus(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allSelectedStatus = false;
      } else if (newValue.length === this.status.length) {
        this.allSelectedStatus = true;
      } else {
        this.allSelectedStatus = false;
      }
    },
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submitAction() {
      this.filter.page = 1;
      this.filter.product_type = this.selected;
      this.filter.status = this.selectedStatus;
      this.$emit("submitFilter", this.filter);
      this.hide();
    },
    toggleAllStatus(checked) {
      if (checked) {
        this.selectedStatus = [0, 1];
      } else {
        this.selectedStatus = [];
      }
    },
    toggleAll(checked) {
      if (checked) {
        this.selected = ["ZFIN", "ZSAM", "ZLPR"];
      } else {
        this.selected = [];
      }
    },
    clearFilter() {
      this.filter.search = null;
      this.allSelectedStatus = false;
      this.allSelected = false;
      this.selected = [];
      this.selectedStatus = [];
      this.filter.status = [];
      this.filter.product_type = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
</style>
